<template>
  <div class="accountList">
    <div v-for="(account, index) in accounts" @click="chooseAccount(index)" :key="index" class="accountRow">
      <div>Email: <span class="boldText">{{ account.user.email }}</span></div>
      <div>User Id: <span class="boldText">{{ account.user.fbuid }}</span></div>
      <div>Status: <span :class="`${account.user.status}Status`">{{ account.user.status }}</span></div>
      <div>Country: {{ account.user.lastCountry.toUpperCase() }}</div>
      <div>Balance: ${{ account.user.internal_balance }}/{{ account.user.balance }}</div>
    </div>
  </div>
</template>

<script>
import { useSearch } from '@/use/search'

export default {
  props: {
    accounts: Array
  },
  setup() {
    const { getUserActivations } = useSearch()
    return { getUserActivations }
  },
  methods: {
    async chooseAccount (i) {
      this.$store.commit('loaders/setCommonLoading', true)
      this.$store.commit('search/setSearchAccount', this.accounts[i])
      window.scrollTo(0, 0)
      await this.getUserActivations(this.accounts[i].user.id)
      this.$store.commit('loaders/setCommonLoading', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .accountList {
    margin: 40px 0;
    .accountRow {
      padding: 15px 10px;
      border-bottom: 1px solid #dddddd;
      cursor: pointer;
      &:hover{
        background: #f8f8f8;
      }
      .boldText{
        font-weight: 500;
      }
    }
  }
</style>
