<template>
  <MappingMenu />
  <b-row class="pt-1 mb-4">
    <b-col sm="3">
      <SearchInput v-model="searchStr" :searchText="searchStr" @search="search" @clearSearch="clearSearch" />
    </b-col>
    <b-col>
      <div class="float-end">
        <b-button @click="showAddModal" variant="primary">Add service</b-button>
      </div>
    </b-col>
  </b-row>
  <b-row>
    <b-col class="mb-5">
      <transition name="fade" mode="out-in">
        <div v-if="loading" class="d-flex justify-content-center mt-4 mb-4">
          <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
        </div>
        <div v-else>
          <b-table responsive hover :fields="fields" :items="services">
            <template #cell(edit)="data">
              <div class="text-center">
                <button @click="showUpdateModal(data)" class="editServiceBtn">
                  <BIconPencilSquare width="24px" height="24px" />
                </button>
              </div>
            </template>
          </b-table>
          <Paginator
            @next="showNextPage"
            @prev="showPrevPage"
            @selectPage="selectPage"
            :pagesCount="this.pagesCount"
            :currentPage="this.currentPage"
            :limit="this.limit"
            :itemsCount="this.itemsCount"
            class="pagination mt-4 mb-4"
          />
        </div>
      </transition>
    </b-col>
  </b-row>
  <ServiceModal
    :showModal="showModal"
    :serviceData="serviceData"
    :mode="mode"
    @serviceSaved="handleServiceSaved"
    @hideModal="hideModal"
  />
</template>

<script>
import { useSmsAdminApi } from '@/use/smsAdminApi'
import MappingMenu from '@/components/Mapping/Menu'
import SearchInput from '@/components/Mapping/Common/SearchInput'
import ServiceModal from '@/components/Mapping/Services/ServiceModal'
import Paginator from '@/components/Paginator.vue'
import { BIconPencilSquare } from 'bootstrap-icons-vue'

export default {
  components: { MappingMenu, SearchInput, ServiceModal, Paginator, BIconPencilSquare },
  emits: ['toggleModal'],
  data: () => {
    return {
      loading: true,
      showModal: false,
      serviceData: {},
      services: [],
      fields: [
        'id',
        'full_name',
        'alt_name',
        'icon_filename',
        'snippet_enabled',
        'warning_enabled',
        { key: 'edit', label: '' }
      ],
      searchStr: '',
      currentPage: 1,
      limit: 100,
      pagesCount: 1,
      itemsCount: null,
      showToast: true,
      mode: 'add'
    }
  },
  setup() {
    const { initApiInst, smsMappingApi } = useSmsAdminApi()
    return { initApiInst, smsMappingApi }
  },
  mounted() {
    this.getServices()
  },
  methods: {
    async getServices() {
      this.loading = true
      this.searchStr = this.$route.query.search_str ?? ''
      const page = Number(this.$route.query.page ?? 1)
      try {
        await this.initApiInst()
        const resp = await this.smsMappingApi.getServices({ searchStr: this.searchStr, page })
        if (resp && resp.services) {
          this.services = resp.services
          this.currentPage = resp.page
          this.limit = resp.limit
          this.pagesCount = resp.pages
          this.itemsCount = resp.total
        }
      } catch (e) {
        this.services = []
      } finally {
        this.loading = false
      }
    },
    async search() {
      if (this.searchStr) {
        this.currentPage = 1
        await this.$router.push({ query: { page: this.currentPage, search_str: this.searchStr } })
        await this.getServices()
      }
    },
    async clearSearch() {
      this.searchStr = ''
      await this.$router.push({ query: null })
      await this.getServices()
    },
    async getByPage(page) {
      await this.$router.push({ query: { ...this.$route.query, page } })
      this.getServices()
    },
    async showNextPage() {
      await this.getByPage(this.currentPage + 1)
    },
    async showPrevPage() {
      await this.getByPage(this.currentPage - 1)
    },
    async selectPage(page) {
      await this.getByPage(page)
    },
    showAddModal() {
      this.serviceData = {}
      this.mode = 'add'
      this.showModal = true
    },
    showUpdateModal(editData) {
      this.serviceData = editData.item
      this.mode = 'edit'
      this.showModal = true
    },
    hideModal() {
      this.showModal = false
      this.serviceData = {}
    },
    async handleServiceSaved() {
      this.hideModal()
      localStorage.removeItem('servicesIdsNames')
      await this.getServices()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/animations/fade-data.scss';

.editServiceBtn {
  border: none;
  background: transparent;
  padding: 0;
}
</style>
