export default {
  namespaced: true,
  state: {
    searchData: null,
    searchAccount: null,
    activationsData: null,
    reasons: {}
  },
  mutations: {
    setSearchData: (state, val) => {
      state.searchAccount = null
      state.searchData = val
      if (val && val.length === 1) {
        state.searchAccount = val[0]
      }
    },
    setSearchAccount: (state, account) => {
      state.searchAccount = account
    },
    setActivationsData: (state, activationsData) => {
      state.activationsData = activationsData
    },
    reasons: (state, reasons) => {
      for (const reason of reasons) {
        if (!state.reasons[reason.type]) {
          state.reasons[reason.type] = {}
        }
        state.reasons[reason.type][reason.id] = reason.reason
      }
    }
  }
}
