<template>
  <b-row v-if="account" class="balanceWrap">
    <b-col cols="12" class="my-3">
      <div class="header">Balance update</div>
    </b-col>
    <b-col class="my-2">
      <b-form @submit="addFundsValidation">
        <b-row class="mb-3">
          <b-col cols="7" lg="3">
            <b-input-group prepend="$" class="mb-2 mr-sm-2 mb-sm-0">
              <b-form-input
                v-model="amount"
                placeholder="0"
              />
            </b-input-group>
          </b-col>
          <b-col cols="5" lg="2">
            <b-button :disabled="loading" type="submit" class="balanceBtn" variant="outline-primary">
              <b-spinner v-if="loading" small variant="primary" />
              <span v-else>Add funds</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      <b-form @submit="validateCode">
        <b-row class="mb-3">
          <b-col cols="7" lg="3">
            <b-form-input
              v-model="code"
              placeholder="123-123-123-123"
            />
          </b-col>
          <b-col cols="5" lg="2">
            <b-button :disabled="loading" type="submit" class="balanceBtn" variant="outline-primary">
              <b-spinner v-if="loading" small variant="primary" />
              <span v-else>Redeem code</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-col>
  </b-row>

  <b-modal v-model="addFundsModal" @keydown.enter="addFunds" title="Are you sure?" class="questionModal" centered hide-footer>
    <div class="btnBl">
      <b-button @click="addFundsModal = false" variant="light">Cancel</b-button>
      <b-button @click="addFunds" variant="danger" class="confirmBtn">Add ${{ amount }}</b-button>
    </div>
  </b-modal>
  <b-modal v-model="redeemCodeModal" @keydown.enter="redeemCode" title="Are you sure?" class="questionModal" centered hide-footer>
    <div class="btnBl">
      <b-button @click="redeemCodeModal = false" variant="light">Cancel</b-button>
      <b-button @click="redeemCode" variant="danger" class="confirmBtn">Redeem code</b-button>
    </div>
  </b-modal>
</template>

<script>
import { useSearch } from '@/use/search'
import { useSmsAdminApi } from '@/use/smsAdminApi'
import { useErrorHandler } from '@/use/errorHandler'

export default {
  props: {
    account: Object
  },
  data () {
    return {
      amount: 0,
      code: null,
      addFundsModal: false,
      redeemCodeModal: false
    }
  },
  computed: {
    loading () {
      return this.$store.state.loaders.commonLoading
    }
  },
  setup () {
    const { initApiInst, smsUserApi } = useSmsAdminApi()
    const { getSearchData } = useSearch()
    const { handleCommonErrors } = useErrorHandler()
    return { initApiInst, smsUserApi, getSearchData, handleCommonErrors }
  },
  methods: {
    addFundsValidation () {
      this.amount = parseFloat(this.amount)
      if (this.amount <= 0) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Amount must be more than $0',
          timeout: 3000,
          name: 'funds_add_error'
        })
      } else {
        this.addFundsModal = true
      }
    },
    async addFunds () {
      try {
        this.addFundsModal = false
        this.$store.commit('loaders/setCommonLoading', true)
        await this.initApiInst()
        const resp = await this.smsUserApi.addFunds(this.account.id, this.amount)
        if (resp && resp.result === 'success') {
          await this.getSearchData(this.account.fbuid)
          this.amount = 0
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Funds successfully added',
            timeout: 3000,
            type: 'success',
            name: 'funds_added'
          })
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.$store.commit('loaders/setCommonLoading', false)
      }
    },
    validateCode () {
      if (this.code) {
        this.redeemCodeModal = true
      } else {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Please enter valid code',
          timeout: 3000,
          name: 'invalid_code'
        })
      }
    },
    async redeemCode () {
      try {
        this.redeemCodeModal = false
        this.$store.commit('loaders/setCommonLoading', true)
        await this.initApiInst()
        const resp = await this.smsUserApi.redeemCode(this.account.id, this.code)
        if (resp && resp.result === 'success') {
          await this.getSearchData(this.account.fbuid)
          this.code = null
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Code successfully redeemed',
            timeout: 3000,
            type: 'success',
            name: 'code_redeemed'
          })
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.$store.commit('loaders/setCommonLoading', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .balanceWrap{
    margin-top: 50px;
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }

    .header{
      font-size: 24px;
      border-bottom: 1px solid #717171;
    }

    .userInfo{
      >div{
        font-size: 18px;
        span{
          color: #7e7e7e;
        }
      }
    }

    input{
      text-align: center;
    }

    .balanceBtn{
      min-width: 130px;
    }
  }
</style>
