import { reactive, toRefs } from 'vue'
import SmsAdminApi from '@/helpers/smsAdminApi'

export function useSmsAdminApi () {
  const smsApiInst = new SmsAdminApi()
  const smsApiInstances = reactive({
    smsUserApi: null,
    smsPurchasesApi: null,
    smsActivationsApi: null,
    smsRewardsApi: null,
    smsMappingApi: null
  })

  const initApiInst = async () => {
    await getUserApi()
    await getPurchasesApi()
    await getActivationsApi()
    await getRewardsApi()
    await getMappingApi()
    await smsApiInst.updateFbToken()
  }

  const getUserApi = async () => {
    if (smsApiInst) {
      smsApiInstances.smsUserApi = await smsApiInst.userApi()
    }
  }

  const getPurchasesApi = async () => {
    if (smsApiInst) {
      smsApiInstances.smsPurchasesApi = await smsApiInst.purchasesApi()
    }
  }

  const getActivationsApi = async () => {
    if (smsApiInst) {
      smsApiInstances.smsActivationsApi = await smsApiInst.activationsApi()
    }
  }

  const getRewardsApi = async () => {
    if (smsApiInst) {
      smsApiInstances.smsRewardsApi = await smsApiInst.rewardsApi()
    }
  }

  const getMappingApi = async () => {
    if (smsApiInst) {
      smsApiInstances.smsMappingApi = await smsApiInst.mappingApi()
    }
  }

  return {
    initApiInst,
    ...toRefs(smsApiInstances)
  }
}
