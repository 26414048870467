<template>
  <MappingMenu />
  <transition name="fade" mode="out-in">
    <div v-if="loading" class="d-flex justify-content-center mt-4 mb-4">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
    </div>
    <div v-else>
      <b-row class="pt-3 mb-4">
        <b-col sm="3" class="mb-3">
          <vue-csv-import
            v-if="countriesProviders.length === 0"
            v-model="csvData"
            :fields="{
              id: { required: true, label: 'id' },
              country_id: { required: true, label: 'country_id' },
              country_name: { required: true, label: 'country_name' },
              provider_country_id: { required: true, label: 'provider_country_id' },
              provider_country_name: { required: true, label: 'provider_country_name' },
              provider_id: { required: true, label: 'provider_id' },
              status: { required: true, label: 'status' }
            }"
          >
            <vue-csv-errors></vue-csv-errors>
            <vue-csv-input></vue-csv-input>
            <vue-csv-map :auto-match="true"></vue-csv-map>
          </vue-csv-import>
          <div class="mt-4">
            <b-button @click="$router.push({ name: 'CountriesProviders' })" variant="outline-primary">
              Reset import mode
            </b-button>
          </div>
        </b-col>
        <b-col sm="3" class="mb-3">
          <label class="mb-2">Provider</label>
          <b-form-select v-model="selectedProvider" @change="handleProviderChange" :options="providers" />
        </b-col>
        <b-col sm="4" class="mb-3">
          <label class="mb-2">Status</label>
          <div class="statusFilters">
            <b-form-checkbox-group v-model="selectedStatuses" @change="handleSelectStatus" :options="mappingStatuses" />
          </div>
        </b-col>

        <b-col sm="2" class="mt-3 mt-sm-0 mb-3">
          <div class="d-none d-sm-block mb-2">&nbsp;</div>
          <div class="text-center">
            <b-button @click="saveChanges" :disabled="csvData.length === 0" variant="primary">Save changes</b-button>
          </div>
          <div v-if="Object.keys(filters).length > 0" class="text-center mt-2">
            <b-button @click="clearFilters()" variant="outline-primary">Clear filters</b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col class="mb-3">
          <div class="providersMappingTableWrap">
            <table class="providersMappingTable">
              <tr class="filters">
                <td>
                  <SearchInput
                    v-model="searchId"
                    :searchText="searchId"
                    @search="searchById"
                    @clearSearch="clearSearchId"
                    placeholder="Id"
                  />
                </td>
                <td>
                  <SearchInput
                    v-model="searchPrId"
                    :searchText="searchPrId"
                    @search="searchByPrId"
                    @clearSearch="clearSearchPrId"
                    placeholder="PC Id"
                  />
                </td>
                <td>
                  <SearchInput
                    v-model="searchPrName"
                    :searchText="searchPrName"
                    @search="searchByPrName"
                    @clearSearch="clearSearchPrName"
                    placeholder="Provider's country name"
                  />
                </td>
                <td>
                  <SearchInput
                    v-model="searchTnName"
                    :searchText="searchTnName"
                    @search="searchByTnName"
                    @clearSearch="clearSearchTnName"
                    placeholder="TN country name"
                  />
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr class="header">
                <td>
                  <div>Id</div>
                </td>
                <td class="providerScId">Provider's country id</td>
                <td>
                  <div>Provider's country name</div>
                </td>
                <td>
                  <div>TN country name</div>
                </td>
                <td class="providerStatus">
                  <div>Status</div>
                </td>
                <td class="providerName">Provider</td>
              </tr>
              <tr v-for="cp in countriesProviders" :key="cp.id" class="dataRow changedDataRow">
                <td>{{ cp.id }}</td>
                <td class="providerScId">{{ cp.provider_country_id }}</td>
                <td>
                  <input
                    @change="handleChangeCpData(cp)"
                    v-model="cp.provider_country_name"
                    type="text"
                    class="form-control"
                  />
                </td>
                <td>
                  <AutoComplete
                    v-model="cp.country_name"
                    optionLabel="name"
                    :suggestions="filteredCountriesNames"
                    @complete="searchAutocomplete"
                    @item-select="changeTnCountry(cp)"
                  />
                </td>
                <td>
                  <b-form-select v-model="cp.status" @change="handleChangeCpData(cp)" :options="mappingStatuses" />
                </td>
                <td class="providerName">{{ getProviderNameById(providers, cp.provider_id) }}</td>
              </tr>
            </table>
            <Paginator
              @next="getByPage(currentPage + 1)"
              @prev="getByPage(currentPage - 1)"
              @selectPage="selectPage"
              :pagesCount="pagesCount"
              :currentPage="currentPage"
              :limit="limit"
              :itemsCount="itemsCount"
              class="pagination mt-4 mb-4"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </transition>
</template>

<script>
import AutoComplete from 'primevue/autocomplete'
import 'primevue/resources/themes/lara-light-teal/theme.css'
import { useSmsAdminApi } from '@/use/smsAdminApi'
import { useMapping } from '@/use/mapping'
import { mappingStatuses } from '@/helpers/commonData'
import MappingMenu from '@/components/Mapping/Menu'
import SearchInput from '@/components/Mapping/Common/SearchInput'
import Paginator from '@/components/Paginator.vue'

export default {
  components: {
    AutoComplete,
    MappingMenu,
    SearchInput,
    Paginator
  },
  data: () => {
    return {
      loading: false,
      countriesProviders: [],
      searchId: null,
      searchPrId: null,
      searchPrName: null,
      searchTnName: null,
      mappingStatuses,
      selectedStatuses: mappingStatuses,
      selectedProvider: 0,
      providers: [],
      currentPage: 1,
      limit: 200,
      pagesCount: 1,
      itemsCount: null,
      countriesIdsNames: [],
      filteredCountriesNames: [],
      csvData: [],
      filteredData: null,
      filters: {}
    }
  },
  setup() {
    const { initApiInst, smsMappingApi } = useSmsAdminApi()
    const { getProviders, getCountriesIdsNames, updateCountriesProviders, getProviderNameById } = useMapping()
    return {
      initApiInst,
      smsMappingApi,
      getProviders,
      getCountriesIdsNames,
      updateCountriesProviders,
      getProviderNameById
    }
  },
  mounted() {
    this.init()
  },
  watch: {
    csvData(val) {
      val.shift()
      this.csvData = val
      this.paginateCPData(JSON.parse(JSON.stringify(this.csvData)), 1)
    }
  },
  methods: {
    async init() {
      this.providers = await this.getProviders()
      this.countriesIdsNames = await this.getCountriesIdsNames()
    },
    paginateCPData(data, page) {
      this.countriesProviders = data.slice((page - 1) * this.limit, page * this.limit)
      this.currentPage = page
      this.itemsCount = data.length
      this.pagesCount = Math.ceil(this.itemsCount / this.limit)
    },
    filterCp() {
      this.filteredData = JSON.parse(JSON.stringify(this.csvData))
      if (this.filters.selectedProvider) {
        this.filteredData = this.filteredData.filter(
          (item) => Number(item.provider_id) === this.filters.selectedProvider
        )
      }
      if (this.filters.selectedStatuses) {
        this.filteredData = this.filteredData.filter((item) => this.filters.selectedStatuses.includes(item.status))
      }
      if (this.filters.searchId) {
        this.filteredData = this.filteredData.filter((item) => item.id.indexOf(this.filters.searchId) > -1)
      }
      if (this.filters.searchPrId) {
        this.filteredData = this.filteredData.filter(
          (item) => item.provider_country_id.toLowerCase().indexOf(this.filters.searchPrId.toLowerCase()) > -1
        )
      }
      if (this.filters.searchPrName) {
        this.filteredData = this.filteredData.filter(
          (item) => item.provider_country_name.toLowerCase().indexOf(this.filters.searchPrName.toLowerCase()) > -1
        )
      }
      if (this.filters.searchTnName) {
        this.filteredData = this.filteredData.filter(
          (item) => item.country_name.toLowerCase().indexOf(this.filters.searchTnName.toLowerCase()) > -1
        )
      }
      this.getByPage(1)
    },
    handleProviderChange(val) {
      this.selectedProvider = Number(val)
      this.selectedProvider
        ? (this.filters.selectedProvider = this.selectedProvider)
        : delete this.filters.selectedProvider
      this.filterCp()
    },
    handleSelectStatus(val) {
      this.selectedStatuses = val
      this.selectedStatuses.length !== this.mappingStatuses.length
        ? (this.filters.selectedStatuses = this.selectedStatuses)
        : delete this.filters.selectedStatuses
      this.filterCp()
    },
    searchById() {
      this.searchId ? (this.filters.searchId = this.searchId) : delete this.filters.searchId
      this.filterCp()
    },
    clearSearchId() {
      this.searchId = ''
      delete this.filters.searchId
      this.filterCp()
    },
    searchByPrId() {
      this.searchPrId ? (this.filters.searchPrId = this.searchPrId) : delete this.filters.searchPrId
      this.filterCp()
    },
    clearSearchPrId() {
      this.searchPrId = ''
      delete this.filters.searchPrId
      this.filterCp()
    },
    searchByPrName() {
      this.searchPrName ? (this.filters.searchPrName = this.searchPrName) : delete this.filters.searchPrName
      this.filterCp()
    },
    clearSearchPrName() {
      this.searchPrName = ''
      delete this.filters.searchPrName
      this.filterCp()
    },
    searchByTnName() {
      this.searchTnName ? (this.filters.searchTnName = this.searchTnName) : delete this.filters.searchTnName
      this.filterCp()
    },
    clearSearchTnName() {
      this.searchTnName = ''
      delete this.filters.searchTnName
      this.filterCp()
    },
    getByPage(page) {
      this.paginateCPData(this.filteredData ?? JSON.parse(JSON.stringify(this.csvData)), page)
    },
    selectPage(page) {
      this.getByPage(page)
    },
    async clearFilters() {
      this.selectedProvider = 0
      this.selectedStatuses = this.mappingStatuses
      this.searchId = null
      this.searchPrId = null
      this.searchPrName = null
      this.searchTnName = null
      this.filters = {}
      this.filteredData = null
      this.paginateCPData(JSON.parse(JSON.stringify(this.csvData)), 1)
    },
    searchAutocomplete(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredCountriesNames = JSON.parse(JSON.stringify(this.countriesIdsNames))
        } else {
          this.filteredCountriesNames = this.countriesIdsNames.filter((country) => {
            return country.name.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    },
    handleChangeCpData(cpItem) {
      const changedItemIndex = this.csvData.findIndex((item) => item.id === cpItem.id)
      this.csvData[changedItemIndex] = cpItem
    },
    changeTnCountry(cp) {
      if (cp.country_name && cp.country_name.id && cp.country_name.name) {
        const changedItemIndex = this.csvData.findIndex((item) => item.id === cp.id)
        cp.country_id = cp.country_name.id
        cp.country_name = cp.country_name.name
        this.csvData[changedItemIndex] = cp
      }
    },
    async saveChanges() {
      const items = JSON.parse(JSON.stringify(this.csvData))
      if (items.length > 0) items.map((item) => delete item.country_name)
      this.loading = true
      const resp = await this.updateCountriesProviders(items)
      if (resp.result && resp.result === 'success') {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Countries Providers successfully updated',
          timeout: 3000,
          type: 'success',
          name: 'success_countries_providers_updated'
        })
        await this.$router.push({ name: 'CountriesProviders' })
      }
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/animations/fade-data.scss';
@import '../../assets/css/mapping.scss';
</style>
