<template>
  <b-row class="mb-4 mb-md-5">
    <b-col>
      <b-button
        @click="$router.push({ name: 'Home' })"
        :variant="pageMode === 'support' ? 'secondary' : 'outline-secondary'"
        class="mb-2 mb-md-0 mr-2"
      >
        Support mode
      </b-button>
      <b-button @click="$router.push({ name: 'Mapping' })" :variant="pageMode === 'mapping' ? 'secondary' : 'outline-secondary'">Mapping mode</b-button>
    </b-col>
    <b-col>
      <div class="float-end">
        <Logout />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Logout from '@/components/Logout'

export default {
  components: { Logout },
  data: () => ({
    pageMode: ''
  }),
  watch: {
    $route (route) {
      this.getPageMode(route)
    }
  },
  mounted () {
    this.getPageMode(this.$route)
  },
  methods: {
    getPageMode (route) {
      if (route.meta.layout) {
        this.pageMode = route.meta.pageMode
      } else {
        this.pageMode = 'support'
      }
    }
  }
}
</script>
