import * as SmsAdminSdk from '@privatix/sms-admin-sdk'
import { getAuth } from 'firebase/auth'

let userApi = null
let purchasesApi = null
let activationsApi = null
let rewardsApi = null
let mappingApi = null

class SmsAdminApi {
  constructor () {
    this.defaultClient = SmsAdminSdk.ApiClient.instance
    this.defaultClient.basePath = process.env.VUE_APP_API_BASE_PATH
  }

  async updateFbToken () {
    const auth = getAuth()
    if (auth.currentUser) {
      this.defaultClient.authentications.jwtAuth.accessToken = await auth.currentUser.getIdToken()
    }
  }

  async userApi () {
    if (!userApi) {
      userApi = new SmsAdminSdk.UserApi()
    }

    return userApi
  }

  async purchasesApi () {
    if (!purchasesApi) {
      purchasesApi = new SmsAdminSdk.PurchasesApi()
    }

    return purchasesApi
  }

  async activationsApi () {
    if (!activationsApi) {
      activationsApi = new SmsAdminSdk.ActivationsApi()
    }

    return activationsApi
  }

  async rewardsApi () {
    if (!rewardsApi) {
      rewardsApi = new SmsAdminSdk.RewardsApi()
    }

    return rewardsApi
  }

  async mappingApi () {
    if (!mappingApi) {
      mappingApi = new SmsAdminSdk.MappingApi()
    }

    return mappingApi
  }
}

export default SmsAdminApi
