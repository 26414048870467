<template>
  <b-row v-if="searchAccount.purchases" class="purchasesWrap">
    <b-col cols="12" class="my-3">
      <div class="header">Purchases</div>
    </b-col>
    <b-col class="my-2">
      <div class="table-responsive mb-5">
        <table class="table">
          <thead>
            <tr>
              <th class="">Date</th>
              <th class="">Order #</th>
              <th class="">Payment method</th>
              <th class="">Status</th>
              <th class="text-center">Amount</th>
              <th class="">Refund</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in purchasesArr" :key="index" class="">
              <td v-if="'leftRefund' in row">{{ format(new Date(row.date), 'yyyy-MM-dd HH:mm:ss') }}</td>
              <td v-else>
                <span class="refundTr"><span class="refundDot">•</span>{{ format(new Date(row.date), 'yyyy-MM-dd HH:mm:ss') }}</span>
              </td>
              <td v-if="row.orderId" @click="copyToCb($refs['order' + index][0], row.orderId)" class="cursorPointer">
                <span :ref="`order${index}`">{{ row.orderId }}</span>
              </td>
              <td v-else class="text-center">-</td>
              <td>{{ row.status }}</td>
              <td v-if="row.gateway" class="">{{ row.gateway === 'gplay' ? 'Google Play' : (row.gateway === 'appstore' ? 'Itunes' : row.gateway.charAt(0).toUpperCase() + row.gateway.slice(1)) }}</td>
              <td v-else>Refund</td>
              <td class="text-center">{{ !('leftRefund' in row) ? '-' : '' }}{{ row.amount }}</td>
              <td v-if="'leftRefund' in row" class="">
                <form v-if="['paddle', 'gplay', 'stripe'].includes(row.gateway) && row.leftRefund > 0" @submit.prevent="getRefundAmount(row.id)">
                  <div class="refundBl">
                    <div class="input-group mr-sm-2 mb-sm-0 refundInput">
                      <span class="input-group-text"><span>$</span></span>
                      <input :value="row.leftRefund" :ref="`refund${row.id}`" class="form-control text-center" type="text" :disabled="(row.gateway=='gplay')">
                    </div>
                    <button :disabled="loading" class="btn btn-outline-primary refundBtn" type="submit">
                      <b-spinner v-if="loading" small variant="primary" />
                      <span v-else>Refund</span>
                    </button>
                  </div>
                </form>
              </td>
              <td v-else />
            </tr>
          </tbody>
        </table>
      </div>
    </b-col>
  </b-row>

  <b-modal v-model="refundModal" @keydown.enter="refund" title="Are you sure?" class="questionModal" centered hide-footer>
    <div class="btnBl">
      <b-button @click="this.refundModal = false" variant="light">Cancel</b-button>
      <b-button @click="refund" variant="danger" class="confirmBtn">Refund ${{ amount }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import { format } from 'date-fns'
import { useSearch } from '@/use/search'
import { useSmsAdminApi } from '@/use/smsAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import { useCopy } from '@/use/copy'

export default {
  data () {
    return {
      format,
      purchasesArr: [],
      amount: 0,
      purchaseId: null,
      refundModal: false
    }
  },
  computed: {
    loading () {
      return this.$store.state.loaders.commonLoading
    },
    searchAccount () {
      return this.$store.state.search.searchAccount
    }
  },
  watch: {
    searchAccount (val) {
      if (val) {
        this.formPurchasesArr()
      }
    }
  },
  setup () {
    const { getSearchData } = useSearch()
    const { initApiInst, smsPurchasesApi } = useSmsAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    const { copyToCb } = useCopy()
    return { getSearchData, initApiInst, smsPurchasesApi, handleCommonErrors, copyToCb }
  },
  mounted () {
    this.formPurchasesArr()
  },
  methods: {
    formPurchasesArr () {
      this.purchasesArr = []
      if (this.searchAccount.purchases.length > 0) {
        for (const purchase of this.searchAccount.purchases) {
          this.purchasesArr.push(purchase)
          if (purchase.refunds && purchase.refunds.length > 0) {
            this.purchasesArr.push(...purchase.refunds)
          }
        }
      }
    },
    getRefundAmount (id) {
      this.clearRefundData()
      const inputRef = this.$refs[`refund${id}`]
      if (inputRef && inputRef.length > 0) {
        const amount = parseFloat(inputRef[0].value)
        if (!isNaN(amount) && amount > 0) {
          this.amount = amount
          this.purchaseId = id
          this.refundModal = true
        } else {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Please, input valid refund amount',
            timeout: 3000,
            name: 'refund_amount_invalid'
          })
        }
      }
    },
    async refund () {
      this.refundModal = false
      this.$store.commit('loaders/setCommonLoading', true)
      try {
        await this.initApiInst()
        const resp = await this.smsPurchasesApi.refundPurchase(this.purchaseId, this.amount)
        if (resp && resp.result === 'success') {
          await this.getSearchData(this.searchAccount.user.fbuid)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Amount successfully refunded',
            timeout: 3000,
            type: 'success',
            name: 'success_refunded'
          })
        }
      } catch (e) {
        if (e.status === 409) {
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'The refund already exists',
            timeout: 3000,
            name: 'refund_exists'
          })
        } else {
          this.handleCommonErrors(e)
        }
      } finally {
        this.$store.commit('loaders/setCommonLoading', false)
        this.clearRefundData()
      }
    },
    clearRefundData () {
      this.amount = 0
      this.purchaseId = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .purchasesWrap{
    margin-top: 30px;

    .header{
      font-size: 24px;
      border-bottom: 1px solid #717171;
    }

    .table{
      td{
        padding: 15px 10px;
        vertical-align: middle;

        .refundBl{
          display: flex;
          .refundInput{
            width: 120px;
            margin-right: 20px;
          }
        }
        .refundTr{
          padding-left: 5px;
          .refundDot{
            padding: 0 10px;
          }
        }
      }
    }

    .refundBtn{
      min-width: 100px;
      @media screen and (max-width: 600px) {
        min-width: 80px;
      }
    }
  }
  .cursorPointer{
    cursor: pointer;
  }
</style>
