<template>
  <b-row class="rewardsWrap">
    <b-col cols="12" class="my-3">
      <div class="header">Rewards</div>
    </b-col>
    <b-col class="my-2">
      <div v-if="!loading" class="table-responsive mb-5">
        <table class="table">
          <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Created at</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in rewards" :key="index" class="">
            <td>{{ row.id }}</td>
            <td>{{ row.type === 'dailyReward' ? 'Daily Reward' : row.type === 'wheelOfLuck' ? 'Spin' : 'Slot' }}</td>
            <td>${{ row.amount }}</td>
            <td>{{ format(new Date(row.createdAt), 'yyyy-MM-dd HH:mm:ss') }}</td>
          </tr>
          </tbody>
        </table>
        <Paginator
          @next="showNextPage"
          @prev="showPrevPage"
          @selectPage="selectPage"
          :pagesCount="this.pagesCount"
          :currentPage="this.currentPage"
          :limit="this.limit"
          :itemsCount="this.itemsCount"
          class="pagination"
        />
      </div>
      <div v-else class="loadingWrap">
        <b-spinner variant="primary" />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { format } from 'date-fns'
import { useSmsAdminApi } from '@/use/smsAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import Paginator from '@/components/Paginator.vue'

export default {
  components: {
    Paginator
  },
  data () {
    return {
      loading: true,
      format,
      rewards: [],
      currentPage: 1,
      limit: 20,
      pagesCount: 1,
      itemsCount: null
    }
  },
  computed: {
    searchAccount () {
      return this.$store.state.search.searchAccount
    }
  },
  watch: {
    searchAccount (newVal, oldVal) {
      if (newVal && oldVal && newVal.user.id !== oldVal.user.id) {
        this.getRewards()
      }
    }
  },
  setup () {
    const { initApiInst, smsRewardsApi } = useSmsAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    return { initApiInst, smsRewardsApi, handleCommonErrors }
  },
  mounted () {
    if (this.searchAccount.user && this.searchAccount.user.id) {
      this.getRewards()
    } else {
      this.loading = false
    }
  },
  methods: {
    async getRewards (page = 1) {
      this.loading = true
      try {
        await this.initApiInst()
        const resp = await this.smsRewardsApi.getRewards(this.searchAccount.user.id, { page })
        if (resp !== null && resp.rewards) {
          this.rewards = resp.rewards
          this.currentPage = resp.page
          this.limit = resp.limit
          this.pagesCount = resp.pages
          this.itemsCount = resp.total
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.loading = false
      }
    },
    showNextPage () {
      this.getRewards(this.currentPage + 1)
    },
    showPrevPage () {
      this.getRewards(this.currentPage - 1)
    },
    selectPage (id) {
      this.getRewards(id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .rewardsWrap{
    margin-top: 30px;

    .header{
      font-size: 24px;
      border-bottom: 1px solid #717171;
    }

    .table{
      td{
        padding: 15px 10px;
        vertical-align: middle;
      }
    }
  }
  .loadingWrap{
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
