<template>
  <MappingMenu />
  <transition name="fade" mode="out-in">
    <div v-if="loading" class="d-flex justify-content-center mt-4 mb-4">
      <b-spinner variant="primary" style="width: 3rem; height: 3rem" />
    </div>
    <div v-else>
      <b-row class="pt-3 mb-4">
        <b-col sm="3" class="mb-3">
          <label class="mb-2">Provider</label>
          <b-form-select v-model="selectedProvider" @change="handleProviderChange" :options="providers" />
        </b-col>
        <b-col sm="4" class="mb-3">
          <label class="mb-2">
            Status
            <div
              @click="
                handleSelectStatus(
                  mappingStatuses.length !== selectedStatuses.length ? mappingStatuses : defaultSelectedMappingStatuses
                )
              "
              class="selectAllStatusesBtn"
            >
              Select {{ mappingStatuses.length !== selectedStatuses.length ? 'all' : 'default' }}
            </div>
          </label>
          <div class="statusFilters">
            <b-form-checkbox-group v-model="selectedStatuses" @change="handleSelectStatus" :options="mappingStatuses" />
          </div>
        </b-col>
        <b-col sm="3" class="mb-3">
          <label class="d-none d-sm-block mb-2">&nbsp;</label>
          <div class="importExportWrap text-center">
            <button @click="$router.push({ name: 'ServicesProvidersImport' })">
              <div>Import</div>
              <BIconFileEarmarkArrowDown width="40px" height="44px" />
            </button>
            <button @click="exportToFile">
              <div>Export</div>
              <BIconFileEarmarkArrowUp width="40px" height="44px" />
            </button>
          </div>
        </b-col>
        <b-col sm="2" class="mt-3 mt-sm-0 mb-3">
          <div class="d-none d-sm-block mb-2">&nbsp;</div>
          <div class="text-center">
            <b-button @click="saveChanges" :disabled="Object.keys(updatedItems).length === 0" variant="primary">
              Save changes
            </b-button>
          </div>
          <div v-if="Object.keys($route.query).length > 0" class="text-center mt-2">
            <b-button @click="clearFilters()" variant="outline-primary">Clear filters</b-button>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col class="mb-3">
          <div class="providersMappingTableWrap">
            <table class="providersMappingTable">
              <tr class="filters">
                <td>
                  <SearchInput
                    v-model="searchId"
                    :searchText="searchId"
                    @search="searchById"
                    @clearSearch="clearSearchId"
                    placeholder="Id"
                  />
                </td>
                <td>
                  <SearchInput
                    v-model="searchPrSrvId"
                    :searchText="searchPrSrvId"
                    @search="searchByPrSrvId"
                    @clearSearch="clearSearchPrSrvId"
                    placeholder="PS Id"
                  />
                </td>
                <td>
                  <SearchInput
                    v-model="searchPrSrvName"
                    :searchText="searchPrSrvName"
                    @search="searchByPrSrvName"
                    @clearSearch="clearSearchPrSrvName"
                    placeholder="Provider's service name"
                  />
                </td>
                <td>
                  <SearchInput
                    v-model="searchTnName"
                    :searchText="searchTnName"
                    @search="searchByTnName"
                    @clearSearch="clearSearchTnName"
                    placeholder="TN service name"
                  />
                </td>
                <td></td>
                <td></td>
                <td><VueDatePicker :model-value="dateRange" @update:model-value="handleDateRangeChange" range /></td>
              </tr>
              <tr class="header">
                <td>
                  <div>
                    Id
                    <SortIcon
                      @sortByField="sortField('id')"
                      :sortAsc="sortAscField === 'id'"
                      :sortDesc="sortDescField === 'id'"
                    />
                  </div>
                </td>
                <td class="providerScId">
                  <div>
                    Provider's service id
                    <SortIcon
                      @sortByField="sortField('provider_service_id')"
                      :sortAsc="sortAscField === 'provider_service_id'"
                      :sortDesc="sortDescField === 'provider_service_id'"
                    />
                  </div>
                </td>
                <td>
                  <div>
                    Provider's service name
                    <SortIcon
                      @sortByField="sortField('provider_service_name')"
                      :sortAsc="sortAscField === 'provider_service_name'"
                      :sortDesc="sortDescField === 'provider_service_name'"
                    />
                  </div>
                </td>
                <td>
                  <div>
                    TN service name
                    <SortIcon
                      @sortByField="sortField('tn_full_name')"
                      :sortAsc="sortAscField === 'tn_full_name'"
                      :sortDesc="sortDescField === 'tn_full_name'"
                    />
                  </div>
                </td>
                <td class="providerStatus">
                  <div>
                    Status
                    <SortIcon
                      @sortByField="sortField('status')"
                      :sortAsc="sortAscField === 'status'"
                      :sortDesc="sortDescField === 'status'"
                    />
                  </div>
                </td>
                <td class="providerName">Provider</td>
                <td>
                  <div>
                    Updated
                    <SortIcon
                      @sortByField="sortField('updated_at')"
                      :sortAsc="sortAscField === 'updated_at'"
                      :sortDesc="sortDescField === 'updated_at'"
                    />
                  </div>
                </td>
              </tr>
              <tr
                v-for="sp in servicesProviders"
                :key="sp.id"
                :class="{
                  changedDataRow: Object.keys(updatedItems).includes(sp.id.toString()) && sp.status !== 'mapped',
                  changedDataRowMapped: Object.keys(updatedItems).includes(sp.id.toString()) && sp.status === 'mapped'
                }"
                class="dataRow"
              >
                <td>{{ sp.id }}</td>
                <td class="providerScId">{{ sp.provider_service_id }}</td>
                <td>
                  <input
                    @change="handleChangeSpData(sp)"
                    v-model="sp.provider_service_name"
                    type="text"
                    class="form-control"
                  />
                </td>
                <td>
                  <AutoComplete
                    v-model="sp.service_name"
                    optionLabel="full_name"
                    :suggestions="filteredServicesNames"
                    @complete="searchAutocomplete"
                    @item-select="changeTnService(sp)"
                  >
                    <template #empty>
                      <b-button @click="addNewService(sp)" variant="info">Add this service</b-button>
                    </template>
                  </AutoComplete>
                </td>
                <td>
                  <b-form-select v-model="sp.status" @change="handleChangeSpData(sp)" :options="mappingStatuses" />
                </td>
                <td class="providerName">{{ getProviderNameById(providers, sp.provider_id) }}</td>
                <td>{{ sp.updated_at ? format(new Date(sp.updated_at), 'yyyy-MM-dd HH:mm:ss') : '' }}</td>
              </tr>
            </table>
            <Paginator
              @next="showNextPage"
              @prev="showPrevPage"
              @selectPage="selectPage"
              :pagesCount="pagesCount"
              :currentPage="currentPage"
              :limit="limit"
              :itemsCount="itemsCount"
              class="pagination mt-4 mb-4"
            />
            <div v-if="pagesCount === 1" class="itemsFoundCount">{{ itemsCount }} items found</div>
          </div>
        </b-col>
      </b-row>
    </div>
  </transition>
  <ServiceModal
    :showModal="addServiceModal"
    :serviceData="newServiceData"
    @serviceSaved="handleServiceSaved"
    @hideModal="hideServiceModal"
  />
</template>

<script>
import AutoComplete from 'primevue/autocomplete'
import 'primevue/resources/themes/lara-light-teal/theme.css'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { useSmsAdminApi } from '@/use/smsAdminApi'
import { useMapping } from '@/use/mapping'
import { useService } from '@/use/service'
import { mappingStatuses, defaultSelectedMappingStatuses } from '@/helpers/commonData'
import MappingMenu from '@/components/Mapping/Menu'
import SearchInput from '@/components/Mapping/Common/SearchInput'
import SortIcon from '@/components/Mapping/Common/SortIcon'
import Paginator from '@/components/Paginator.vue'
import ServiceModal from '@/components/Mapping/Services/ServiceModal'
import { BIconFileEarmarkArrowUp, BIconFileEarmarkArrowDown } from 'bootstrap-icons-vue'

export default {
  components: {
    AutoComplete,
    VueDatePicker,
    MappingMenu,
    SearchInput,
    SortIcon,
    Paginator,
    ServiceModal,
    BIconFileEarmarkArrowUp,
    BIconFileEarmarkArrowDown
  },
  data: () => {
    return {
      format,
      loading: true,
      servicesProviders: [],
      dateRange: [],
      startDate: null,
      endDate: null,
      searchId: null,
      searchPrSrvId: null,
      searchPrSrvName: null,
      searchTnName: null,
      mappingStatuses,
      selectedStatuses: defaultSelectedMappingStatuses,
      selectedProvider: 0,
      providers: [],
      sortAscField: null,
      sortDescField: null,
      currentPage: 1,
      limit: 200,
      pagesCount: 1,
      itemsCount: null,
      updatedItems: {},
      servicesIdsNames: [],
      filteredServicesNames: [],
      addServiceModal: false,
      newServiceData: {},
      mappingServiceData: null
    }
  },
  setup() {
    const { initApiInst, smsMappingApi } = useSmsAdminApi()
    const { getProviders, getServicesIdsNames, updateServicesProviders, getProviderNameById } = useMapping()
    const { createServiceIdFromName } = useService()
    return {
      initApiInst,
      smsMappingApi,
      getProviders,
      getServicesIdsNames,
      updateServicesProviders,
      getProviderNameById,
      createServiceIdFromName
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      this.providers = await this.getProviders()
      this.servicesIdsNames = await this.getServicesIdsNames()
      await this.getServicesProviders()
    },
    async getFiltersData() {
      this.selectedProvider = this.$route.query.provider || 0

      if (this.$route.query['statuses[]']) {
        this.selectedStatuses = this.$route.query['statuses[]']
        if (typeof this.selectedStatuses === 'string') {
          this.selectedStatuses = [this.selectedStatuses]
        }
      } else {
        this.selectedStatuses = defaultSelectedMappingStatuses
      }

      this.searchId = this.$route.query.id || null
      this.searchPrSrvId = this.$route.query.providerSrvId || null
      this.searchPrSrvName = this.$route.query.providerSrvName || null
      this.searchTnName = this.$route.query.tnName || null

      this.dateRange = []
      this.startDate = this.$route.query.startDate || null
      if (this.startDate) {
        this.dateRange.push(new Date(this.startDate * 1000))
      }
      this.endDate = this.$route.query.endDate || null
      if (this.endDate) {
        if (this.dateRange.length === 0) this.dateRange.push(null)
        this.dateRange.push(new Date(this.endDate * 1000))
      }

      this.sortAscField = this.$route.query.sortAscField || undefined
      this.sortDescField = this.$route.query.sortDescField || undefined

      this.currentPage = this.$route.query.page || 1
    },
    async getServicesProviders(resetPage = false) {
      this.loading = true
      if (resetPage) {
        this.currentPage = 1
        await this.$router.push({ query: { ...this.$route.query, page: undefined } })
      }
      this.getFiltersData()
      try {
        await this.initApiInst()
        const resp = await this.smsMappingApi.getServicesProviders({
          providerId: this.selectedProvider,
          statuses: this.selectedStatuses,
          id: this.searchId,
          prvSrvId: this.searchPrSrvId,
          prvSrvName: this.searchPrSrvName,
          tnSrvName: this.searchTnName,
          updatedFrom: this.startDate,
          updatedTo: this.endDate,
          sortAsc: this.sortAscField,
          sortDesc: this.sortDescField,
          page: this.currentPage,
          limit: this.limit
        })
        if (resp && resp.services_providers) {
          this.servicesProviders = resp.services_providers
          this.currentPage = resp.page
          this.limit = resp.limit
          this.pagesCount = resp.pages
          this.itemsCount = resp.total
        }
      } catch (e) {
        this.servicesProviders = []
      } finally {
        this.loading = false
      }
    },
    async handleProviderChange(val) {
      this.selectedProvider = Number(val)
      await this.$router.push({ query: { ...this.$route.query, provider: this.selectedProvider || undefined } })
      await this.getServicesProviders(true)
    },
    async handleSelectStatus(val) {
      this.selectedStatuses = val && val.length > 0 ? val : defaultSelectedMappingStatuses
      const statusesArr =
        JSON.stringify(this.selectedStatuses) !== JSON.stringify(defaultSelectedMappingStatuses)
          ? this.selectedStatuses
          : undefined
      await this.$router.push({ query: { ...this.$route.query, 'statuses[]': statusesArr } })
      await this.getServicesProviders(true)
    },
    async handleDateRangeChange(val) {
      this.dateRange = val
      if (this.dateRange && this.dateRange.length === 2) {
        const startDate = Math.floor(new Date(this.dateRange[0]).getTime() / 1000)
        if (this.dateRange[1]) {
          const endDate = Math.floor(new Date(this.dateRange[1]).getTime() / 1000)
          await this.$router.push({ query: { ...this.$route.query, startDate, endDate } })
        } else {
          await this.$router.push({ query: { ...this.$route.query, startDate } })
        }
      } else {
        await this.$router.push({ query: { ...this.$route.query, startDate: undefined, endDate: undefined } })
      }
      await this.getServicesProviders(true)
    },
    async searchById() {
      await this.$router.push({ query: { ...this.$route.query, id: this.searchId || undefined } })
      await this.getServicesProviders(true)
    },
    async clearSearchId() {
      this.searchId = ''
      await this.$router.push({ query: { ...this.$route.query, id: undefined } })
      await this.getServicesProviders(true)
    },
    async searchByPrSrvId() {
      await this.$router.push({ query: { ...this.$route.query, providerSrvId: this.searchPrSrvId || undefined } })
      await this.getServicesProviders(true)
    },
    async clearSearchPrSrvId() {
      this.searchPrSrvId = ''
      await this.$router.push({ query: { ...this.$route.query, providerSrvId: undefined } })
      await this.getServicesProviders(true)
    },
    async searchByPrSrvName() {
      await this.$router.push({ query: { ...this.$route.query, providerSrvName: this.searchPrSrvName || undefined } })
      await this.getServicesProviders(true)
    },
    async clearSearchPrSrvName() {
      this.searchPrSrvName = ''
      await this.$router.push({ query: { ...this.$route.query, providerSrvName: undefined } })
      await this.getServicesProviders(true)
    },
    async searchByTnName() {
      await this.$router.push({ query: { ...this.$route.query, tnName: this.searchTnName || undefined } })
      await this.getServicesProviders(true)
    },
    async clearSearchTnName() {
      this.searchTnName = ''
      await this.$router.push({ query: { ...this.$route.query, tnName: undefined } })
      await this.getServicesProviders(true)
    },
    async sortField(val) {
      if (this.sortAscField === val) {
        await this.$router.push({ query: { ...this.$route.query, sortAscField: undefined, sortDescField: val } })
      } else if (this.sortDescField === val) {
        await this.$router.push({ query: { ...this.$route.query, sortAscField: undefined, sortDescField: undefined } })
      } else {
        await this.$router.push({ query: { ...this.$route.query, sortAscField: val, sortDescField: undefined } })
      }
      await this.getServicesProviders()
    },
    async getByPage(page) {
      await this.$router.push({ query: { ...this.$route.query, page } })
      await this.getServicesProviders()
    },
    async showNextPage() {
      await this.getByPage(this.currentPage + 1)
    },
    async showPrevPage() {
      await this.getByPage(this.currentPage - 1)
    },
    async selectPage(page) {
      await this.getByPage(page)
    },
    async clearFilters() {
      await this.$router.push({ query: undefined })
      await this.getServicesProviders()
    },
    async exportToFile() {
      this.loading = true
      try {
        await this.initApiInst()
        const respData = await this.smsMappingApi.getServicesProvidersCsv({
          providerId: this.selectedProvider,
          statuses: this.selectedStatuses,
          id: this.searchId,
          prvSrvId: this.searchPrSrvId,
          prvSrvName: this.searchPrSrvName,
          tnSrvName: this.searchTnName,
          updatedFrom: this.startDate,
          updatedTo: this.endDate,
          sortAsc: this.sortAscField,
          sortDesc: this.sortDescField
        })
        if (respData) {
          const blob = new Blob([respData], { type: 'text/csv' })
          saveAs(blob, `services_providers-${Date.now()}.csv`)
        }
      } catch (e) {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Something went wrong. Try again later',
          timeout: 3000,
          type: 'error',
          name: 'sp_csv_error'
        })
      } finally {
        this.loading = false
      }
    },
    searchAutocomplete(event) {
      setTimeout(() => {
        if (!event.query.trim().length) {
          this.filteredServicesNames = [...this.servicesIdsNames]
        } else {
          this.filteredServicesNames = this.servicesIdsNames.filter((service) => {
            return service.full_name.toLowerCase().startsWith(event.query.toLowerCase())
          })
        }
      }, 250)
    },
    handleChangeSpData(spItem) {
      this.updatedItems[spItem.id] = spItem
    },
    changeTnService(sp) {
      if (sp.service_name && sp.service_name.id && sp.service_name.full_name) {
        sp.service_id = sp.service_name.id
        sp.service_name = sp.service_name.full_name
        sp.status = 'mapped'
        this.updatedItems[sp.id] = sp
        this.filteredServicesNames = []
      }
    },
    async saveChanges() {
      const items = JSON.parse(JSON.stringify(Object.values(this.updatedItems)))
      if (items.length > 0)
        items.map((item) => {
          delete item.updated_at
          delete item.service_name
          return item
        })

      this.loading = true
      const resp = await this.updateServicesProviders(items)
      if (resp.result && resp.result === 'success') {
        this.$store.commit('alert/add', {
          id: Date.now(),
          text: 'Services Providers successfully updated',
          timeout: 3000,
          type: 'success',
          name: 'success_services_providers_updated'
        })
        this.updatedItems = []
        await this.getServicesProviders()
      }
      this.loading = false
    },
    addNewService(service) {
      this.mappingServiceData = service
      const serviceName = service.provider_service_name
      const serviceId = this.createServiceIdFromName(serviceName)
      this.newServiceData = {
        id: serviceId,
        full_name: serviceName,
        alt_name: serviceName
      }
      this.addServiceModal = true
    },
    async handleServiceSaved(service) {
      if (service && service.id && service.full_name) {
        this.mappingServiceData.service_id = service.id
        this.mappingServiceData.service_name = service.full_name
        this.mappingServiceData.status = 'mapped'
        this.updatedItems[this.mappingServiceData.id] = this.mappingServiceData
      }
      this.hideServiceModal()
      localStorage.removeItem('servicesIdsNames')
      this.servicesIdsNames = await this.getServicesIdsNames()
    },
    hideServiceModal() {
      this.addServiceModal = false
      this.newServiceData = {}
      this.mappingServiceData = {}
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/animations/fade-data.scss';
@import '../../assets/css/mapping.scss';
</style>
