<template>
  <b-container class="mt-4">
    <HeaderBl />
    <router-view/>
  </b-container>
</template>

<script>
import HeaderBl from '@/components/HeaderBl'

export default {
  components: { HeaderBl }
}
</script>
