import { useStore } from 'vuex'
import { useSmsAdminApi } from '@/use/smsAdminApi'
import { useErrorHandler } from '@/use/errorHandler'

export function useSearch () {
  const store = useStore()
  const { initApiInst, smsUserApi, smsActivationsApi } = useSmsAdminApi()
  const { handleCommonErrors } = useErrorHandler()

  const getSearchData = async (uid, email = '', orderId = '', activationId = '') => {
    if (!uid && !email && !orderId && !activationId) return
    try {
      await initApiInst()
      const searchData = await smsUserApi.value.getUserInfo({ email, uid, orderId, activationId })
      const reasons = await smsActivationsApi.value.getReasons()
      store.commit('search/setSearchData', searchData)
      store.commit('search/reasons', reasons)
      if (searchData.length === 1) {
        await getUserActivations(searchData[0].user.id)
      }
    } catch (e) {
      handleCommonErrors(e)
      store.commit('search/setSearchData', null)
      store.commit('search/setActivationsData', null)
    }
  }

  const getUserActivations = async (userId, page = 1) => {
    try {
      await initApiInst()
      const activationsData = await smsActivationsApi.value.getActivations(userId, { page })
      store.commit('search/setActivationsData', activationsData)
    } catch (e) {
      handleCommonErrors(e)
      store.commit('search/setActivationsData', null)
    }
  }


  return { getSearchData, getUserActivations }
}
