<template>
    <b-row v-if="activationsData && activationsData.activations" class="activationsWrap">
        <b-col cols="12" class="my-3">
      <div class="header">Activations</div>
    </b-col>
    <b-col class="my-2">
      <div class="table-responsive mb-5">
        <table class="table">
          <thead>
            <tr>
              <th class="">Date</th>
              <th class="">Activation #</th>
              <th class="">Number</th>
              <th class="">Service/<br>Country</th>
              <th class="">Status/<br>Old Status</th>
              <th class="">Message</th>
              <th class="">Code</th>
              <th class="">Price</th>
              <th class="">User reason</th>
              <th class="">Support reason</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in activationsArr" :key="index" class="">
              <td>{{ format(new Date(row.created_at), 'yyyy-MM-dd HH:mm:ss') }}</td>
              <td>{{ row.id }}</td>
              <td>{{ row.number }}</td>
              <td>{{ row.service.toLowerCase() }}/<br>{{ row.country.toLowerCase() }}</td>
              <td>{{ row.status }}/<br>{{ row.old_status }}</td>
              <td>{{ row.message }}</td>
              <td>{{ row.code }}</td>
              <td>${{ Number(row.price) }}</td>
              <td v-if="row.feedback != null && row.feedback.user_reason != null">
                <button :disabled="loading" class="btn btn-outline-primary refundBtn" type="button" @click=" preSetActivationReason(row.id, 'user', row.feedback.user_reason)">
                    <b-spinner v-if="loading" small variant="primary" />
                    <span v-else>{{ reasons['user'][row.feedback.user_reason] }}</span>
                  </button>
              </td>
              <td v-else>
                <button :disabled="loading" class="btn btn-outline-primary refundBtn" type="button" @click=" preSetActivationReason(row.id, 'user')">
                    <b-spinner v-if="loading" small variant="primary" />
                    <span v-else>Set reason</span>
                  </button>
              </td>
              <td v-if="row.feedback != null && row.feedback.support_reason != null">
                <button :disabled="loading" class="btn btn-outline-primary refundBtn" type="button" @click=" preSetActivationReason(row.id, 'support', row.feedback.support_reason)">
                    <b-spinner v-if="loading" small variant="primary" />
                    <span v-else>{{ reasons['support'][row.feedback.support_reason] }}</span>
                  </button>
              </td>
              <td v-else>
                <button :disabled="loading" class="btn btn-outline-primary refundBtn" type="button" @click=" preSetActivationReason(row.id, 'support')">
                    <b-spinner v-if="loading" small variant="primary" />
                    <span v-else>Set reason</span>
                  </button>
              </td>
              <td class="">
                <div v-if="['refunded',  'error', 'supRefunded'].indexOf(row.status) === -1" class="refundBl">
                  <button :disabled="loading" class="btn btn-outline-primary refundBtn" type="button" @click=" preRefundActivation(row.id)">
                    <b-spinner v-if="loading" small variant="primary" />
                    <span v-else>Refund</span>
                  </button>
                </div>
                <div v-else class="refundBl">
                  <button  class="btn btn-outline-primary refundBtn" type="button" disabled>
                    <b-spinner v-if="loading" small variant="primary" />
                    <span v-else>Refunded</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Paginator
            @next="showNextPage"
            @prev="showPrevPage"
            @selectPage="selectPage"
            :pagesCount="this.pagesCount"
            :currentPage="this.currentPage"
            :limit="this.limit"
            :itemsCount="this.itemsCount"
            class="pagination"
          />
      </div>
    </b-col>
    </b-row>
  <b-modal v-model="activationRefundModal" @keydown.enter="activationRefund" title="Are you sure?" class="questionModal" centered hide-footer>
    <div class="btnBl">
      <b-button @click="this.activationRefundModal = false" variant="light">Cancel</b-button>
      <b-button @click="activationRefund" variant="danger" class="confirmBtn">Refund activation #{{ this.activationId }}</b-button>
    </div>
  </b-modal>
  <b-modal v-model="selectActivationReasonModal" :title="'Select reason for activation #'+this.activationId"   class="questionModal" centered hide-footer>
    <div class="d-block text-center">
      <div v-for="(row, index) in reasons[this.reasonType]" :key="index">
        <label><input  name="reason" type="radio" :value="index"  class="" :checked="index == this.selectedReason" v-on:change="this.selectedReason = index">&nbsp;{{ row }}</label>
      </div>
      <br><br>
    </div>
    <div class="btnBl">
      <b-button @click="this.selectActivationReasonModal = false" variant="light">Cancel</b-button>
      <b-button @click="updateActivation" variant="danger" class="confirmBtn">Update reason for activation #{{ this.activationId }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import { format } from 'date-fns'
import { useSearch } from '@/use/search'
import { useSmsAdminApi } from '@/use/smsAdminApi'
import { useErrorHandler } from '@/use/errorHandler'
import Paginator from '@/components/Paginator.vue'

export default {
  components: {
    Paginator
  },
  data () {
    return {
      format,
      activationsArr: [],
      activationId: null,
      activationRefundModal: false,
      currentPage: 1,
      limit: 10,
      pagesCount: 1,
      itemsCount: null,
      reasonType: 'user',
      selectActivationReasonModal: false,
      selectedReason: null
    }
  },
  computed: {
    loading () {
      return this.$store.state.loaders.commonLoading
    },
    activationsData () {
      return this.$store.state.search.activationsData
    },
    searchAccount () {
      return this.$store.state.search.searchAccount
    },
    reasons () {
      return this.$store.state.search.reasons
    }
  },
  setup () {
    const { getSearchData, getUserActivations } = useSearch()
    const { initApiInst, smsActivationsApi } = useSmsAdminApi()
    const { handleCommonErrors } = useErrorHandler()
    return { getSearchData, getUserActivations, initApiInst, smsActivationsApi, handleCommonErrors }
  },
  watch: {
    activationsData (val) {
      if (val) {
        this.formActivationsArr()
      }
    }
  },
  mounted () {
    this.formActivationsArr()
  },
  methods: {
    formActivationsArr () {
      this.activationsArr = []
      if (this.activationsData !== null && this.activationsData.activations.length > 0) {
        for (const activation of this.activationsData.activations) {
          this.activationsArr.push(activation)
        }
        this.currentPage = this.activationsData.page
        this.limit = this.activationsData.limit
        this.pagesCount = this.activationsData.pages
        this.itemsCount = this.activationsData.total
      }
    },
    preRefundActivation (id) {
      this.activationRefundModal = true
      this.activationId = id
    },
    async activationRefund () {
      this.activationRefundModal = false
      this.$store.commit('loaders/setCommonLoading', true)
      try {
        await this.initApiInst()
        const resp = await this.smsActivationsApi.refundActivation(this.activationId)
        if (resp && resp.result === 'success') {
          await this.getSearchData(this.searchAccount.user.fbuid)
          this.$store.commit('alert/add', {
            id: Date.now(),
            text: 'Activation  successfully refunded',
            timeout: 3000,
            type: 'success',
            name: 'success_refunded'
          })
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.$store.commit('loaders/setCommonLoading', false)
        this.clearData()
      }
    },
    clearData () {
      this.activationId = null
      this.reasonType = null
      this.activationRefundModal = false
      this.selectActivationReasonModal = false
    },
    showNextPage () {
      this.openPage(this.currentPage + 1)
    },
    showPrevPage () {
      this.openPage(this.currentPage - 1)
    },
    selectPage (id) {
      this.openPage(id)
    },
    async openPage (page) {
      this.$store.commit('loaders/setCommonLoading', true)
      await this.getUserActivations(this.searchAccount.user.id, page)
      this.$store.commit('loaders/setCommonLoading', false)
      this.clearData()
    },
    preSetActivationReason (activationId, reasonType, selectedReason = null) {
      this.activationId = activationId
      this.reasonType = reasonType
      this.selectActivationReasonModal = true
      this.selectedReason = selectedReason
    },
    async updateActivation () {
      this.selectActivationReasonModal = false
      this.$store.commit('loaders/setCommonLoading', true)
      try {
        await this.initApiInst()
        const payload = {
          activation_id: this.activationId
        }
        if (this.reasonType === 'user') {
          payload.userReason = Number(this.selectedReason)
        }
        if (this.reasonType === 'support') {
          payload.supportReason = Number(this.selectedReason)
        }
        const resp = await this.smsActivationsApi.activationsFeedback({ rating: payload })
        if (resp && resp.result === 'success') {
          await this.getSearchData(this.searchAccount.user.fbuid)
        }
      } catch (e) {
        this.handleCommonErrors(e)
      } finally {
        this.$store.commit('loaders/setCommonLoading', false)
        this.clearData()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .activationsWrap{
    margin-top: 30px;

    .header{
      font-size: 24px;
      border-bottom: 1px solid #717171;
    }
  }
</style>
